import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

const Requisitos = ({ requisitos, setRequisitos, setCamera }) => {

    return (
        <Collapse in={requisitos}>
            <Grid item md={12} xs={12} lg={12} sx={{ px: 2, backgroundColor: "#fff", boxShadow: 10 }}>
                <Grid container flexDirection={"column"}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="#2356a9" sx={{ mt: 2 }}>Requisitos.</Typography>
                        <List>
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Busca un lugar con buena iluminación y con fondo blanco. No se aceptan fotos con decoración o muebles." />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="La foto debe ser tipo carnet: centrada, mirada de frente y sin inclinar la cabeza. No se permiten fotos tipo “selfie”." />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="La imagen debe ser nítida." />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="No utilices accesorios que tapen tu rostro o cuello (Ejemplo: gorros grandes, lentes de sol, bufandas, etc.)" />
                            </ListItem>
                            <Divider component="li" />
                            <ListItem alignItems="flex-start">
                                <ListItemText primary="Esta fotografía es un ejemplo de como debes tomar la foto para cumplir con todos los requisitos de aprobación." />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <Grid item lg={4} md={4} xs={12}>
                                    <img width={"100%"} height={"auto"} src="/images/tomatufoto.2.jpg" alt="Fotografía de ejemplo" />
                                </Grid>

                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch checked={!requisitos} onChange={() => {
                                    setRequisitos(!requisitos);
                                    setCamera(true);
                                }} name="requisitos" />
                            }
                            sx={{ my: 1 }}
                            label="Entiendo los requisitos."
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>)
}

export default Requisitos;