import { useState, useEffect, useContext } from "react";

import { Typography, Card, CardHeader, CardContent, Box, Grid, Chip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import { SolicitudContext } from "../../../context/Solicitud/SolicitudContext";

const EstadoTne = ({ mensaje, mensajeSolicitud }) => {
    const { tne, error } = useContext(SolicitudContext);
    const [loading, setLoading] = useState(true);
    const [estado, setEstado] = useState('No habilitado para tomar fotografía');
    const [color, setColor] = useState("default");

    useEffect(() => {

        mensaje = "";
        setEstado("");
        setLoading(false);
        setColor(mensaje === "RECHAZADA" ? "error" : mensaje === "APROBADA" ? "success" : "default");

        if (mensajeSolicitud) {
            setEstado(mensajeSolicitud);
            setLoading(false);
            setColor(mensaje === "RECHAZADA" ? "error" : mensaje === "APROBADA" ? "success" : "default");
        }

    }, [mensajeSolicitud])



    const renderSolicitudTne = (Solicitud) => {
    
        return (<>
            <Divider component="li" sx={{ my: 2 }} />
            <Typography
                component="li"
                variant="h6"
                color="#1a232f"
                key={0}
            >Solicitud</Typography>
            <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid item>
                    <Typography
                        component="li"
                        variant="body1"
                        color="#1a232f"
                        fontWeight={"bold"}
                        key={1}
                    >Estado</Typography>
                </Grid>
                <Grid item>
                    <Chip color={color} label={tne !== null ? mensaje === "RECEPCIONADA" || mensaje === "RECEPCIONADA SEDE" || mensaje === "ENTREGADA" || mensaje === "ENVÍO A SEDE" ? "ENVIADA" : tne.solicitud.tipoSolicitud === "RECHAZO" ? mensaje : (mensaje === "APROBADA" && tne.solicitud.tipo_solicitud !== "Y") ? "FOTO APROBADA POR USS" : mensaje ? (mensaje !== "" && tne.solicitud.tipo_solicitud) === "Y" ? "NO DISPONIBLE" : mensaje : "DISPONIBLE" : ""} />                    
                </Grid>
            </Grid>
        </>)
    }

    const isMsjTNE = (tne !== null) ? tne.mensaje : "";
    let msjTNE;
    if (isMsjTNE) {
        msjTNE = isMsjTNE;
    } else {
        msjTNE = "";
    }

    const isSolicitud = (tne !== null) ? tne.solicitud : "";
    let msjSolicitud;
    if (isSolicitud) {
        msjSolicitud = isSolicitud;
    } else {
        msjSolicitud = "";
    }

    return (<Card>
        <CardHeader
            title="TNE"
            titleTypographyProps={{ align: 'center', color: "#2356a9", fontWeight: "light" }}
            action={null}
            sx={{
                backgroundColor: "#edf2f8"
            }}
        />
        <CardContent>
            {
                loading ?
                    <>
                        <Box sx={{ textAlign: "center" }}><CircularProgress /> </Box>
                        {error && <Typography>Error de conexin</Typography>}
                    </> :
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                mb: 2,
                            }}
                        >
                            <Typography component="h6" variant="h6" sx={{ fontWeight: "bold" }} color="text.primary">
                                {estado}
                            </Typography>
                        </Box>
                        <ul>
                            
                            {
                                {msjTNE} && <Typography
                                    component="li"
                                    variant="subtitle1"
                                    align="center"
                                    key={0}
                                >{msjTNE}</Typography> 
                            }

                            {
                                // Se comenta por orden del PO hasta nuevo aviso
                                //msjSolicitud && renderSolicitudTne(msjSolicitud)
                            }
                        </ul>
                    </>
            }
        </CardContent>
    </Card>)
}
export default EstadoTne;