
const SolicitudReducer = (state, action) => {

    const { type, payload } = action;
    switch (type) {
        case 'GET_SOLICITUD':
            return {
                ...state,
                solicitud: payload
            }
        case 'GET_TNE':
            return {
                ...state,
                tne: payload
            }
        case 'GET_TUI':
            return {
                ...state,
                tui: payload
            }
        case 'SET_ERROR':        
            return {
                ...state,
                error: payload,
                [payload.type] : payload.message

            }
        default:
            return state;
    }
}

export default SolicitudReducer;