import { guardarTUI, guardarTNE } from '../models/ServicioAws';

const enviarSolicitud = (req, res) => {

    var bodyFormDataTne = new FormData()
    bodyFormDataTne.append('rut', req.Rut);
    bodyFormDataTne.append('campus', req.Campus);
    bodyFormDataTne.append('carrera', req.Carrera);
    bodyFormDataTne.append('fotografia', req.Tne ? req.Tne.split("base64,")[1] : null);

    var bodyFormDataTui = new FormData()
    bodyFormDataTui.append('rut', req.Rut);
    bodyFormDataTui.append('campus', req.Campus);
    bodyFormDataTui.append('carrera', req.Carrera);
    bodyFormDataTui.append('fotografia', req.Tui ? req.Tui.split("base64,")[1] : null);

    Promise
        .all([
            guardarTUI({
                rut: req.Rut, 
                campus: req.Campus, 
                carrera: req.Carrera, 
                fotografia: req.Tui ? req.Tui.split("base64,")[1] : null
            }),
            req.Tne ? guardarTNE({
                rut: req.Rut, 
                campus: req.Campus, 
                carrera: req.Carrera, 
                fotografia: req.Tne ? req.Tne.split("base64,")[1] : null
            }) : { statusCode: 200 }
        ])
        .then(response => {

            res({
                tui: response[0]
                //tne: response[1]
            })
        })
}

export { enviarSolicitud };