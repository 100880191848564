
const UserReducer = (state, action) => {

    const { type, payload } = action;

    switch (type) {
        case 'GET_USER':
            return {
                ...state,
                user: payload
            }
        case 'SET_USER':
            return {
                ...state,
                user: payload
            }
        case 'SET_ERROR':
            return {
                ...state,
                error: payload
            }
        case 'LOGOUT':
            return {
                ...state,
                user: payload
            }
        default:
            return state;
    }
}

export default UserReducer;