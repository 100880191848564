import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Navigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';

import SaveIcon from '@mui/icons-material/Save';

//import { useLogin } from '../../services/Tui'
import Footer from '../../common/Footer'
//import { useParams } from 'react-router';

import { UserContext } from '../../../context/User/UserContext'

//const STATE_LOGIN = 'fototui_desa';
const STATE_LOGIN = process.env.REACT_APP_STATE_LOGIN;

function Login() {

    const params = (new URL(document.location)).searchParams;
    const code = params.get("code");

    const { user, error, setUser } = useContext(UserContext);
    const [errorLogin, setErrorLogin] = useState({ status: false, message: "" });
    const [loading, setLoading] = useState(false)

    const processLogin = () => {
        if (code) {
            setLoading(true);
            setUser(code)
        }
    }

    useEffect(() => {
        processLogin();
    }, [code]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (error) {
            setLoading(false)
            setErrorLogin({ status: true, message: error })
        }
        else
            setErrorLogin({ status: false, message: '' })
    }, [error])

    const redirectLogin = () => {
        window.location.href = 'https://login.microsoftonline.com/correouss.onmicrosoft.com/oauth2/authorize?client_id=17d27290-ae6f-45d3-b85a-934ed0ddb028&response_type=code&redirect_uri=https%3A%2F%2Fauth.uss.cl%2Fredirect&response_mode=query&resource=https%3A%2F%2Fgraph.windows.net%2F&state=' + STATE_LOGIN
    }

    if (user && errorLogin.status === false) {
        return <Navigate to="/" />
    }

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${"/images/bellavista.jpg"})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar> */}
                    <img src="/images/logo_uss.png" alt="USS" width="160" />

                    <Typography component="h1" variant="h4" color="#1a232f">
                        Toma tu fotografía
                    </Typography>
                    <Typography variant="h5" component="h1" color="#1a232f">
                        para la TUI
                    </Typography>
                    <Typography sx={{ mt: 2 }} variant="body" textAlign={"center"}>Proceso habilitado para estudiantes nuevos</Typography>
                    <Box sx={{ mt: 1 }}>
                        {
                            loading ?
                                <LoadingButton
                                    color="primary"
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    fullWidth
                                >
                                    Validando tu cuenta
                                </LoadingButton>
                                :
                                <Button
                                    onClick={redirectLogin}
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Iniciar Sesión
                                </Button>
                        }
                        {
                            errorLogin.status && <Alert severity="error">{errorLogin.message}</Alert>
                        }

                        <Typography textAlign="center" >
                            <Link href="https://claveunica.uss.cl" target="_blank" variant="body2">
                                Olvidaste tu clave?
                            </Link>
                        </Typography>

                        <Typography variant="body2" sx={{ mt: 4 }}>
                            Para consultas escríbenos a <Link href="mailto:tomatufoto@uss.cl" target="_blank" variant="body2">
                                tomatufoto@uss.cl
                            </Link>
                        </Typography>

                        <Footer sx={{ mt: 5 }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Login;