import React, { useState, useContext, useEffect } from "react"
import { Grid, Typography, Container } from "@mui/material";
import { Navigate } from "react-router";
import Collapse from '@mui/material/Collapse';

import { UserContext } from "../../../context/User/UserContext";
import { SolicitudContext } from "../../../context/Solicitud/SolicitudContext";

import TakePhoto from "./TakePhoto";
import EstadoTui from "./EstadoTui";
import EstadoTne from "./EstadoTne";

const MSJ_SOLICITADA_TUI = process.env.REACT_APP_URL_MSJ_SOLICITADA_TUI;
const MSJ_SOLICITADA_TNE = process.env.REACT_APP_URL_MSJ_SOLICITADA_TNE;
const MSJ_APROBADA_TUI = process.env.REACT_APP_URL_MSJ_APROBADA_TUI;
const MSJ_APROBADA_TNE = process.env.REACT_APP_URL_MSJ_APROBADA_TNE;
const MSJ_ENTREGADA = process.env.REACT_APP_URL_MSJ_ENTREGADA;
const MSJ_RECHAZADA = process.env.REACT_APP_URL_MSJ_RECHAZADA;
const MSJ_NO_PERMITIDA_TNE = process.env.REACT_APP_URL_MSJ_NO_PERMITIDA_TNE;
const MSJ_NO_PERMITIDA_TUI = process.env.REACT_APP_URL_MSJ_NO_PERMITIDA_TUI;
const MSJ_NO_TOMADA_TNE = "Fotografía no fue solicitada";
const MSJ_HABILITADO = "Habilitado para tomar fotografía"
const MSJ_ENVIADA_TNE = process.env.REACT_APP_URL_MSJ_ENVIADA_TNE;
const MSJ_ENVIADA_TUI = process.env.REACT_APP_URL_MSJ_ENVIADA_TUI;
const MSJ_NO_HABILITADA_TNE = process.env.REACT_APP_URL_MSJ_NO_HABILITADA_TNE;
const MSJ_NO_HABILITADA_TUI = process.env.REACT_APP_URL_MSJ_NO_HABILITADA_TUI;
const Home = () => {

    const { user, getUser, logout } = useContext(UserContext);

    const { solicitud, getTui, tui, getTne, tne, getSolicitud, error } = useContext(SolicitudContext);

    const [loading, setLoading] = useState(true);
    const [showTakePhoto, setShowTakePhoto] = useState(false);
    const [mensajeTui, setMensajeTui] = useState(null);
    const [mensajeTne, setMensajeTne] = useState(null);

    useEffect(() => {
        if (error) {
            //Cerrar la sesión
            //logout()
        }
    }, [error])

    function loadUser() {
        //console.log("hola");
        getUser()
        getTne(user)
        getTui(user)
        getSolicitud(user)
        setLoading(false)
    }

    useEffect(() => {

        loadUser();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    //Efecto para cuando ya se cargan los 3 servicios
    useEffect(() => {                         

        if (solicitud && tui && tne) {

            console.log(solicitud.estado)
            //console.log(tne.solicitud.ruta_foto)            
            let respuestaDefinitivaTNE = '';
            
            // if (typeof tne !== "string") {            
            //     respuestaDefinitivaTNE = 'OK'; // viene la solicitud TNE, existe la solitud TNE, es un 200 por lo tanto es un OK definitivo
            // } else {
            //     respuestaDefinitivaTNE = 'Distinto de OK' // no viene solicitud, no existe la solicitud TNE, viene uno diferente a 200
            // }

            switch (solicitud.estado) {
                case '':
                    setShowTakePhoto(true);
                    setMensajeTui(MSJ_HABILITADO);
                    //setMensajeTne(MSJ_HABILITADO);
                    break;

                case 'SOLICITADA':
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_SOLICITADA_TUI);                                      
                    //setMensajeTne(respuestaDefinitivaTNE ==='OK'? MSJ_SOLICITADA_TNE : MSJ_NO_TOMADA_TNE);
                    break;

                case 'APROBADA':
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_APROBADA_TUI);                    
                    //setMensajeTne(respuestaDefinitivaTNE ==='OK'? MSJ_APROBADA_TNE : MSJ_NO_TOMADA_TNE);
                    break;

                case 'ENTREGADA':
                    setShowTakePhoto(false);
                    setMensajeTui("");
                    //setMensajeTne(respuestaDefinitivaTNE ==='OK'? "" : MSJ_NO_TOMADA_TNE);
                    break;

                case 'RECHAZADA':
                    setShowTakePhoto(true);
                    setMensajeTui(solicitud.mensaje ? solicitud.mensaje : MSJ_RECHAZADA);
                    //setMensajeTne(respuestaDefinitivaTNE ==='OK'? solicitud.mensaje : MSJ_NO_TOMADA_TNE);
                    break;

                case 'NO PERMITIDA':
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_NO_PERMITIDA_TUI);
                    //setMensajeTne(MSJ_NO_PERMITIDA_TNE);
                break;
                
                case 'RECEPCIONADA':
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_NO_HABILITADA_TUI);
                    //setMensajeTne(MSJ_NO_HABILITADA_TNE);
                break;

                case 'ELIMINADA':
                    setShowTakePhoto(true);
                    setMensajeTui(MSJ_HABILITADO);
                    //setMensajeTne(MSJ_HABILITADO);
                    break;
                
                case 'ENVIADA':
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_ENVIADA_TUI);
                    //setMensajeTne(respuestaDefinitivaTNE === 'OK' ? MSJ_ENVIADA_TNE : MSJ_NO_TOMADA_TNE  );
                    break;

                case 'SIN SOLICITUD':
                    setShowTakePhoto(true);
                    setMensajeTui(MSJ_HABILITADO);
                    //setMensajeTne(MSJ_HABILITADO);
                    break;

                case 'RECEPCIONADA SEDE':
                        setShowTakePhoto(false);
                        setMensajeTui(MSJ_NO_HABILITADA_TUI);
                        //setMensajeTne(MSJ_NO_HABILITADA_TNE);
                    break;

                default:
                    console.log('por defecto');
                    setShowTakePhoto(false);
                    setMensajeTui(MSJ_NO_PERMITIDA_TUI);                    
                    //setMensajeTne(MSJ_NO_PERMITIDA_TNE);                    
                    break;
            }
        }

    }, [solicitud, tui, tne])

    if (loading)
        return (
            <Grid>
                <Typography>Cargando...</Typography>
            </Grid>
        )

    if (!user && !loading) {
        return <Navigate to="/login" />
    }   

    return (

        <>
            <Grid container sx={{
                m: 0, p: 0,
                backgroundImage: `url(${"/images/img-fondo.jpg"})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Grid item lg={12} md={12} xs={12} sx={{
                    pb: 4, pt: 7, m: 0,
                    backgroundColor: "rgba(35, 59, 102, 0.6)"
                }}>
                    <Typography
                        component="h2"
                        variant="h2"
                        align="center"
                        color="#fff"
                        gutterBottom
                    >
                        Toma tu Foto
                    </Typography>
                    <Typography variant="h5" align="center" color="#fff" component="p">
                        Toma tu fotografía para la credencial TUI
                    </Typography>
                </Grid>
            </Grid>
            <Container maxWidth="md" component="main" sx={{ pt: 3, pb: 4 }}>
                <Grid sx={{ mb: 2 }}>
                    <Typography variant="h5" sx={{ fontWeight: "medium" }}>{user.info[0].NOMBRE + ' ' + user.info[0].APATERNO.replace('/', ' ')}</Typography>
                    <Typography>{user.info[0].SEDE}</Typography>
                    <Typography sx={{ textTransform: "capitalize" }}>{user.info[0].CARRERA}</Typography>
                </Grid>

                <Collapse in={showTakePhoto}>
                    <Grid sx={{ mb: 2 }}>
                        {
                            showTakePhoto && <TakePhoto value={{ tne, tui, solicitud, user }} />
                        }
                    </Grid>
                </Collapse>

                <Grid container spacing={5} alignItems="flex-start">
                    <Grid                        
                        item
                        lg={12}
                        xs={12}                     
                        md={12} 
                    >
                        <EstadoTui 
                            user={user} 
                            mensaje={solicitud && (solicitud.estado === "SOLICITADA" ? "EN PROCESO" : solicitud.estado)}                             
                            mensajeSolicitud={mensajeTui} 
                        />
                    </Grid>

                    {/* <Grid
                        item
                        key={0}
                        xs={12}
                        sm={12}
                        md={6}
                    >
                        <EstadoTne user={user} 
                        mensaje={solicitud && tne && tne.statusCode===200 && (tne.solicitud.tipoSolicitud === "GRATUITA" ? "NO DISPONIBLE" : solicitud.estado === "SOLICITADA" ? "EN PROCESO" : solicitud.estado)} 
                        mensajeSolicitud={mensajeTne} />                   
                    </Grid> */}

                </Grid>
            </Container>
        </>
    )
}

export default Home;