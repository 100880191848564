import React, { useReducer } from 'react';
import SolicitudReducer from './SolicitudReducer';
import { SolicitudContext } from './SolicitudContext';
import { getSolicitudAWS, getTneAWS, getTuiAWS } from "../../models/ServicioAws";

const SolicitudState = (props) => {
    const initialState = {
        solicitud: null,
        tne: null,
        tui: null,
        error: null
    };

    const [state, dispatch] = useReducer(SolicitudReducer, initialState);

    const getSolicitud = async(user) => {
        if (user) {
            const { info } = user;

            try {
                var datos = await getSolicitudAWS(info[0].RUT);
               
                if (datos.statusCode === 500) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: "Error de conexión"
                    })
                } else if(datos.statusCode === 200){
                    
                    dispatch({
                        type: 'GET_SOLICITUD',
                        payload: datos.body
                    });
                } else {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: {
                            type: "solicitud",
                            message: datos.message
                        }
                    })
                }
            } catch (e) {
                dispatch({
                    type: 'SET_ERROR',
                    payload: e.message
                })
            }
        }

    }

    const getTne = async (user) => {
        if (user) {
            const { info } = user;

            var response = await getTneAWS(info[0].RUT);
                //console.log(response);
            if(response.statusCode === 200)
            {
                dispatch({
                    type: 'GET_TNE',
                    payload: response
                    
                });
            }
            else {
                dispatch({
                    type: 'SET_ERROR',
                    payload: {
                        type: "tne",
                        message: response.message
                    }
                })
            }
        }
    }

    const getTui = async (user) => {
        if (user) {
            const { info } = user;

            var response = await getTuiAWS(info[0].RUT);
            
            if(response.statusCode === 200)
            {
                dispatch({
                    type: 'GET_TUI',
                    payload: response
                });
            }
            else {
                dispatch({
                    type: 'SET_ERROR',
                    payload: {
                        type: "tui",
                        message: response.message
                    }
                })
            }               
        }
    }

    return (
        <SolicitudContext.Provider value={{
            solicitud: state.solicitud,
            tne: state.tne,
            tui: state.tui,
            error: state.error,
            getSolicitud,
            getTne,
            getTui
        }}>
            {props.children}
        </SolicitudContext.Provider>
    )
}

export default SolicitudState;
