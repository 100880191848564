import React from 'react';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

const Condiciones = ({ terminos, setTerminos, setRequisitos }) => {

    return (
        <Collapse in={terminos}>
            <Grid item md={12} xs={12} lg={12} sx={{ px: 2, backgroundColor: "#fff", boxShadow: 10 }}>
                <Grid container flexDirection={"column"}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="#2356a9" sx={{ mt: 2 }}>Términos y condiciones.</Typography>
                        <List>
                            <Grid container sx={{ p: 2 }}>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        1. Toma y carga de la fotografía
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        El estudiante se compromete a utilizar esta plataforma para cargar su fotografía personal, cumpliendo con los requisitos necesarios para su aprobación.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider component="li" />

                            <Grid container sx={{ p: 2 }}>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        2. Aprobación y confección de la fotografía
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        La USS realizará la evaluación inicial de la fotografía, aprobando o rechazando la imagen de acuerdo con el cumplimiento de los requisitos necesarios publicados en este portal. El estudiante deberá ingresar a plataforma tomatufoto.uss.cl 48 horas posterior a la solicitud para conocer el resultado de su evaluación (aprobada o rechazada).
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        En caso de que la fotografía sea rechazada en la evaluación inicial realizada por la USS, se habilitará este portal para que el estudiante realice una nueva toma de fotografía.
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        La aprobación final y emisión de la TUI estará a cargo de Banco Santander.
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        La fotografía puede ser rechazada en la etapa final por Banco Santander por los criterios propios de la entidad para este fin. En caso de ser así, el estudiante será contactado a su correo institucional con la información necesaria para realizar nuevamente el proceso.
                                    </Typography>                                    
                                </Grid>
                            </Grid>
                            <Divider component="li" />
                            <Grid container sx={{ p: 2 }}>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        color="text.primary"
                                    >
                                        3. Entrega de TUI
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Una vez que la TUI sea emitida y recepcionada en el campus, el estudiante recibirá un aviso a su correo institucional con la información de retiro.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </List>
                    </Grid>

                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch checked={!terminos} onChange={() => {
                                    setTerminos(!terminos);
                                    setRequisitos(true);
                                }} name="termino" />
                            }
                            sx={{ my: 1 }}
                            label="Acepto los términos y condiciones"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>)
}

export default Condiciones;