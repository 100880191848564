import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import App from './components/App'
import Login from './components/pages/Login/'
import Home from './components/pages/Home/'
import NotFount from './components/pages/NotFound'

import UserState from './context/User/UserState';
import SolicitudState from './context/Solicitud/SolicitudState';

dotenv.config();

const Router = () => {

  return (<UserState>
    <SolicitudState>
      <BrowserRouter>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/login/:token" element={<Login />} />

          <Route path="/" element={<App />}>

            <Route index element={<Home />} />
            <Route path="*" element={<NotFount />} />

          </Route>

        </Routes>
      </BrowserRouter>
    </SolicitudState>
  </UserState >);
}
ReactDOM.render(

  <Router />

  ,
  document.getElementById('root')
);
