import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import AddAPhoto from '@mui/icons-material/AddAPhoto';
import Camera from '@mui/icons-material/Camera';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Requisitos from './Requisitos';
import Condiciones from './Condiciones';
//import SeleccionFoto from './SeleccionFoto';
import CierreSolicitud from './CierreSolicitud';

import { enviarSolicitud } from '../../../services/Solicitud';

const TakePhoto = ({ value }) => {

    const [open, setOpen] = React.useState(false);
    const [terminos, setTerminos] = React.useState(true);
    const [requisitos, setRequisitos] = React.useState(false);
    //const [seleccionFoto, setSeleccionFoto] = React.useState(false);
    const [tneChecked, setTneChecked] = React.useState(true);
    const [camera, setCamera] = React.useState(false);
    const [orientation, setOrientation] = React.useState("landscape");

    const [enviando, setEnviando] = React.useState(false);
    const [enviada, setEnviada] = React.useState(false);
    const [response, setResponse] = React.useState({ tui: null, tne: null })

    const [openCamera, setOpenCamera] = React.useState(false);
    const [imageDataURL, setImageDataURL] = React.useState(null);
    const [imageDataTuiURL, setImageDataTuiURL] = React.useState(null);

    const [switchError, setSwitchError] = React.useState('')

    const [isMobile, setIsMobile] = React.useState(false);
    const [clientWidth, setClientWidth] = React.useState(1)
    const [clientHeight, setClientHeight] = React.useState(1)

    const playerRef = React.useRef(null);
    const photoTUIRef = React.useRef(null);
    const photoTNERef = React.useRef(null);
    const imageTNERef = React.useRef(null);
    const imageTUIRef = React.useRef(null);
    const dialogContentRef = React.useRef();
    const containerRef = React.useRef(null);

    const { tne, tui, user } = value;

    const handleOpen = () => setOpen(true);
    const handleClose = () => {

        setTerminos(true);
        setRequisitos(false);
        setEnviada(false);
        setCamera(false);
        setEnviada(false);
        setTneChecked(true);
        //setSeleccionFoto(false);
        closeCamera();
        setOpen(false);
    }

    React.useEffect(() => {

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function (e) {
                // `this` is now pointing to `window`, not the component. So use `self`.
                try {
                    if (window.orientation === 90 || window.orientation === -90) {
                        setOrientation("landscape")
                    }
                } catch (error) {
                    console.error(error)
                }

            }, false);
        }
    }, [])

    React.useEffect(() => {

        setIsMobile(getMobileOS());
        if (camera) {
            openCameraAction();
        } else {
            closeCamera();
        }
    }, [camera])

    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return "iOS"
        }
        return false
    }

    const openCameraAction = async () => {
        cleanPhoto()
        setOpenCamera(true)

        if (isMobile === "iOS") {

            var currentOrientation = window.orientation
            if (currentOrientation === 0) {
                window.alert("Gira el dispositivo para tomar la foto")
                setOrientation("portrait")
                setSwitchError("Gira el dispositivo para tomar la foto");
                setOpenCamera(false)
                return
            }
        }

        if (orientation === "landscape") {
            try {
                navigator.mediaDevices
                    .getUserMedia(isMobile
                        ? {
                            video: {
                                facingMode: { exact: "environment" },
                                width: 300
                            }, audio: false
                        }
                        : { video: { width: 500 }, audio: false })
                    .then(stream => {
                        let video = playerRef.current;
                        video.srcObject = stream;
                        video.play();

                        let { width, height } = stream.getTracks()[0].getSettings();

                        var container = containerRef.current;
                        var winSize = -0.000625 * container.offsetWidth + 1.188
                        var resizeWidth = container.offsetWidth * winSize;

                        var resizeHeight = height / (width / resizeWidth)

                        setClientWidth(width);
                        setClientHeight(height);
                        /* video.setAttribute("width", resizeWidth);
                        video.setAttribute("height", resizeHeight); */
                    })
                    .catch(err => {
                        setOpenCamera(false);
                        setSwitchError('No se puede acceder a la cámara de tu dispositivo, otorga los permisos en tu configuración de sistema.');
                        console.error(err)
                    })
            } catch (error) {
                setSwitchError("No se detectan cámaras en tu dispositivo");
                setOpenCamera(false);
            }
        }

    }

    const processTnePhoto = (video) => {
        let photoTNE = photoTUIRef.current;
        let imageTNE = imageTNERef.current;

        const widthTNE = clientHeight / 1.25;;
        const heightTNE = clientHeight;
        const marginLeftTNE = isMobile === "Android" ? 0 : (widthTNE - clientWidth) / 2;;

        photoTNE.width = widthTNE;
        photoTNE.height = heightTNE;

        let ctx = photoTNE.getContext('2d');
        ctx.drawImage(video, marginLeftTNE, 0, isMobile ? clientWidth + 10 : clientWidth, isMobile ? clientHeight + 10 : clientHeight);

        imageTNE.setAttribute('src', photoTNE.toDataURL());
        //setImageDataURL(photoTNE.toDataURL());
    }

    const processTuiPhoto = (video) => {
        let photoTUI = photoTNERef.current;
        let imageTUI = imageTUIRef.current;

        const widthTUI = clientHeight / 1.3;
        const heightTUI = clientHeight;
        const marginLeftTUI = isMobile === "Android" ? 0 : (widthTUI - clientWidth) / 2;

        photoTUI.width = widthTUI;
        photoTUI.height = heightTUI;

        let ctx = photoTUI.getContext('2d');
        ctx.drawImage(video, marginLeftTUI, 0, clientWidth + 20, clientHeight + 20);
        imageTUI.setAttribute('src', photoTUI.toDataURL('image/png'));
        setImageDataTuiURL(photoTUI.toDataURL('image/png'));
    }
    const pickPhoto = async () => {
    
        var currentOrientation = window.orientation
        if (isMobile === "iOS" && currentOrientation === 0) {
            window.alert("Gira el dispositivo para tomar la foto")
            setOrientation("portrait")
            setSwitchError("Gira el dispositivo para tomar la foto");
        } else {
            let video = await playerRef.current;

            setSwitchError('');
            //if (tneChecked)
            //    processTnePhoto(video);
            processTuiPhoto(video);

            video.srcObject.getVideoTracks().forEach((track) => {
                track.stop();
            });
            setOpenCamera(false);
        }
    }

    const closeCamera = async () => {
        let video = await playerRef.current;
        if (video && video.srcObject)
            video.srcObject.getVideoTracks().forEach((track) => {
                track.stop();
            });
        setSwitchError('')
        //setImageDataURL(null);
        setImageDataTuiURL(null);
        setOpenCamera(false);
    }

    const cleanPhoto = () => {
        let photo = photoTUIRef.current;
        let ctx = photo.getContext('2d');

        ctx.clearRect(0, 0, photo.width, photo.height)

        setImageDataURL(null)
        setImageDataTuiURL(null)
        setSwitchError('')
    }

    const handleSend = () => {


        const widthTne = 200;
        const heightTne = 250;
        const widthTui = 180;
        const heightTui = 240;

        //Formateo TNE
        let photoTNE = photoTUIRef.current;
        let imageTNE = imageTNERef.current;
        photoTNE.width = widthTne;
        photoTNE.height = heightTne;
        var urlTne = '';
        
        // if (tneChecked) {
        
        //     let ctx = photoTNE.getContext('2d');
        //     ctx.fillRect(0, 0, photoTNE.width, photoTNE.height);
        //     ctx.drawImage(imageTNE, 0, 0, photoTNE.width, photoTNE.height);

        //     urlTne = tneChecked ? photoTNE.toDataURL('image/jpeg', 1) : null;
        
        //     var size = urlTne.length * 3 / 4;
        //     var resolution = [.9, .8, .7, .6, .5, .4, .3, .2];
        //     var i = 0;
        //     while (size > 48 * 1024 && resolution.length > i) {
        //         urlTne = photoTNE.toDataURL('image/jpeg', resolution[i]);
        //         size = urlTne.length * 3 / 4;
        //         i++;
        //     }
        // }
        
        //Formateo TUI
        let photoTUI = photoTUIRef.current;
        let imageTUI = imageTUIRef.current;
        photoTUI.width = widthTui;
        photoTUI.height = heightTui;

        let ctx = photoTUI.getContext('2d');
        ctx.fillRect(0, 0, photoTUI.width, photoTUI.height);
        ctx.drawImage(imageTUI, 0, 0, photoTUI.width, photoTUI.height);
        var urlTui = photoTUI.toDataURL('image/jpeg', 1);
        
        if (urlTui) {
            setCamera(false);
            //setImageDataURL(null);
            setImageDataTuiURL(null);
            setEnviada(true);
            setEnviando(true);

            enviarSolicitud({
                Rut: user.info[0].RUT,
                Campus: user.info[0].CODIGO_SEDE,
                Carrera: user.info[0].CODIGO_CARRERA,
                Tui: urlTui,
                Tne: urlTne,
                TOKEN: user.token
            }, res => {
                setEnviando(false);
                setResponse(res)
            })
        }
    }

    return (
        <>
            <Button onClick={handleOpen} startIcon={<AddAPhoto />} fullWidth variant="contained" size="large" sx={{ fontWeight: "medium" }}>
                Tomar foto
            </Button>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll="paper"
                maxWidth="lg"
            >
                <DialogTitle style={{ fontWeight: "bold", color: "#464e5b" }} id="responsive-dialog-title">
                    {"Toma tu foto"}
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: "#edf1fa" }} ref={dialogContentRef}>
                    <Grid container justifyContent="center" alignItems="center" spacing={0} sx={{ mt: 2 }}>
                        
                        <Condiciones terminos={terminos} setTerminos={setTerminos} setRequisitos={setRequisitos} />
                        <Requisitos requisitos={requisitos} setRequisitos={setRequisitos} setCamera={setCamera} />                        
                        {/* <SeleccionFoto seleccionFoto={seleccionFoto} setSeleccionFoto={setSeleccionFoto} setCamera={setCamera} tneChecked={tneChecked} setTneChecked={setTneChecked} /> */}
                        {
                            camera &&
                            <Grid item md={8} xs={12} lg={12} xl={12} alignSelf="center" sx={{ alignItems: "center", alignContent: "center", boxShadow: 10, pb: 2, mt: 0, backgroundColor: "#fff", textAlign: "center" }}>
                                {openCamera && <>
                                    <Grid ref={containerRef} container sx={{ justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "center", position: "relative" }}>
                                        <video
                                            style={{ position: "relative", zIndex: 1 }}
                                            ref={playerRef}
                                            autoPlay
                                            playsInline
                                        ></video>
                                        {/* <div id="frametne" style={{ width: (clientHeight / 1.25), height: clientHeight, left: "auto", right: "auto", position: "absolute", borderRight: "5px solid", borderLeft: "5px solid", borderColor: "red", zIndex: 1000, top: 0 }} ></div> */}
                                        <div id="frametui" style={{ width: (clientHeight / 1.3), height: clientHeight, left: "auto", right: "auto", position: "absolute", borderRight: "5px solid", borderLeft: "5px solid", borderColor: "purple", zIndex: 1000, top: 0 }} ></div>
                                        <Grid sx={{ textAlign: "center", position: "absolute", bottom: 5, zIndex: 1200 }}>
                                            <Button
                                                sx={{
                                                    color: "#fff",
                                                    border: "2px white solid"
                                                }}
                                                variant="outlined" onClick={pickPhoto}>
                                                <Camera fontSize="large" />
                                                <Typography variant="caption">Tomar foto</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </>
                                }
                                <Grid container spacing={1} sx={{ flexDirection: "row", alignItems: "flex-end", p: 2 }}>
                                    <Grid xs={12} md={6} display={imageDataURL ? "block" : "none"} item>
                                        <Card >
                                            <img id="imageTNE" ref={imageTNERef} alt="TNE" style={{ width: "100%", maxWidth: 300, height: "auto" }} />
                                            <canvas style={{ display: "none" }} ref={photoTUIRef}></canvas>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Fotografía TNE
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Proporción 4/5
                                                </Typography>
                                            </CardContent>
                                        </Card>

                                    </Grid>
                                    <Grid xs={12} md={12} display={imageDataTuiURL ? "block" : "none"} item>
                                        <Card >
                                            <img id="imageTUI" ref={imageTUIRef} alt="TUI" style={{ width: "100%", maxWidth: 300, height: "auto" }} />
                                            <canvas style={{ display: "none" }} ref={photoTNERef}></canvas>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Fotografía TUI
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Proporción 3/4
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>


                                {
                                    switchError !== '' && <Alert sx={{ my: 1 }} severity="warning" onClose={() => setSwitchError('')}>{switchError}</Alert>
                                }
                                {
                                    (!openCamera && !imageDataTuiURL && !enviada) &&
                                    <Grid sx={{ textAlign: "center", width: clientWidth, height: clientHeight }}>
                                        <Button startIcon={<AddAPhoto />} variant="outlined" onClick={openCameraAction}>Abrir cámara</Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                        {
                            enviada && <>
                                <CierreSolicitud value={{ enviando, response }} />
                            </>
                        }

                    </Grid>
                </DialogContent>
                {
                    !openCamera && <DialogActions>
                        {
                            (!openCamera && imageDataTuiURL) &&
                            <Button startIcon={<AddAPhoto />} onClick={openCameraAction}>Volver a tomar</Button>
                        }
                        {
                            (imageDataURL || imageDataTuiURL) &&
                            <Button autoFocus variant="contained" onClick={handleSend}>
                                Enviar
                            </Button>
                        }
                        {
                            !enviando && !enviada && <Button onClick={handleClose} autoFocus>
                                Cancelar
                            </Button>
                        }
                        {
                            enviada && <a href="/" style={{ textDecoration: "none" }}><Button>Cerrar</Button></a>
                        }

                    </DialogActions>
                }
            </Dialog>
        </>
    )
}

export default TakePhoto;