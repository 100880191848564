import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

const Footer = (props) => {

    return (
        <Grid container sx={{ flexDirection: "column", textAlign: "center", alignItems: "center", mb: 4, mt: 5 }}>
            <img src="/images/acreditacion.png" alt="USS" width="200" />
            <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ mt: 1 }}>
                {'© '}
                <Link color="inherit" href="https://www.uss.cl/">
                    Universidad San Sebastián
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>

        </Grid >
    )
}

export default Footer;