import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useEffect, useState } from 'react';

const CierreSolicitud = ({ value }) => {

    const { enviando, response } = value;
    const [error, setError] = useState(false);

    useEffect(() => {
        if (response.tui || response.tne) {
            if (response.tui.statusCode !== 200 && response.tne.statusCode !== 200)
                setError(true);
        }
    }, [response])

    return (
        <Grid lg={12} xs={12} md={12} item sx={{ textAlign: "center", boxShadow: 10 }}>
            {
                enviando
                && <>
                    <Card sx={{ boxShadow: 0, px: 2, backgroundColor: "#1a232f" }}>
                        <CardContent>
                            <CircularProgress sx={{ color: "#fff", mt: 4, mb: 2 }} />
                            <Typography variant="body1" fontWeight={"bold"} color="#fff">Estamos enviando la solicitud, espera un momento...</Typography>
                        </CardContent>
                    </Card>
                </>
            }
            <Collapse in={!enviando}>
                <Card sx={{ boxShadow: 0, px: 2, backgroundColor: "#fff" }}>
                    <CardContent>

                        {
                            !error ?
                                <>
                                    <CheckCircleOutlineIcon sx={{ color: "green", fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" color="#2356a9" component="div">
                                        Solicitud exitosa
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                    Revisa esta plataforma en 48 horas más para conocer el estado de tu solicitud.
                                    </Typography>
                                </> :
                                <>
                                    <ErrorOutline sx={{ color: "red", fontSize: 50 }} />
                                    <Typography gutterBottom variant="h5" color="#2356a9" component="div">
                                        Error de envío de solicitud
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                </>
                        }

                    </CardContent>
                </Card>
            </Collapse>
        </Grid >)
}
export default CierreSolicitud;