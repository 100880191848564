import { Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const notFound = () => {
    return (
        <Grid
            textAlign="center"
            alignContent="center"
        >
            <Typography variant="h1" >404</Typography>
            <Typography>Página no encontrada</Typography>
            <Link to="/">Ir a inicio.</Link>
        </Grid>
    )
}

export default notFound;