import { createTheme, ThemeProvider } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router';

import Header from "./common/Header";
import Footer from "./common/Footer"

const theme = createTheme();

const App = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <Header />
                <Outlet />
                <Footer />
            </ThemeProvider>
        </>
    )
}

export default App;