import { useContext, useEffect, useState } from "react";

import { Typography, Card, CardHeader, CardContent, Box, Grid, Chip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import { SolicitudContext } from "../../../context/Solicitud/SolicitudContext";


const EstadoTui = ({ mensaje, mensajeSolicitud }) => {

    const { tui, error } = useContext(SolicitudContext);
    const [loading, setLoading] = useState(true)
    const [estado, setEstado] = useState('');
    const [color, setColor] = useState("default");

    useEffect(() => {
        if (mensajeSolicitud) {
            setEstado(mensajeSolicitud)
            setLoading(false)
            setColor(mensaje === "RECHAZADA" ? "error" : mensaje === "APROBADA" ? "success" : "default");
        }

    }, [mensajeSolicitud])

    const renderSolicitudTui = (Solicitud) => {
        return (<>
            <Divider component="li" sx={{ my: 2 }} />
            <Typography
                component="li"
                variant="h6"
                color="#1a232f"
                key={0}
            >Solicitud</Typography>
            <Grid container sx={{ justifyContent: "space-between" }}>
                <Grid item>
                    <Typography
                        component="li"
                        variant="body1"
                        color="#1a232f"
                        fontWeight={"bold"}
                        key={1}
                    >Estado</Typography>
                </Grid>
                <Grid item>
                    <Chip color={color} label={mensaje ? mensaje === "APROBADA" ? "FOTO APROBADA POR USS" : mensaje : Solicitud.Permitida === "Y" ? 'DISPONIBLE' : 'NO DISPONIBLE'} />
                </Grid>
            </Grid>
        </>)
    }

    return (<Card>
        <CardHeader
            title="TUI"
            titleTypographyProps={{ align: 'center', color: "#2356a9", fontWeight: "light" }}
            action={null}
            sx={{
                backgroundColor: "#edf2f8"
            }}
        />
        <CardContent>
            {
                !tui ?
                    <>
                        {
                            loading
                                ? <Box sx={{ textAlign: "center" }}><CircularProgress /> </Box>
                                : error ? <Typography>Error de conexión</Typography> : <Typography>Sin información</Typography>
                        }
                    </> :
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                mb: 2,
                            }}
                        >
                            <Typography component="h6" variant="h6" sx={{ fontWeight: "bold" }} color="text.primary">
                                {estado}
                            </Typography>
                        </Box>
                        <ul>
                            {
                                tui.mensaje && <Typography
                                    component="li"
                                    variant="subtitle1"
                                    align="center"
                                    key={0}
                                >{tui.mensaje}</Typography>
                            }

                            {
                                // Se comenta por orden del PO hasta nuevo aviso
                                //tui.solicitud && renderSolicitudTui(tui.solicitud)
                            }
                        </ul>
                    </>
            }
        </CardContent>
    </Card>)
}
export default EstadoTui;