import React, { useReducer } from 'react';
import UserReducer from './UserReducer';
import { UserContext } from './UserContext';
import axios from 'axios';
import { generarToken } from "../../models/ServicioAws";

const URL_BASE_AUTH = process.env.REACT_APP_URL_BASE_AUTH;

const UserState = (props) => {
    const initialState = {
        user: null,
        error: null
    };

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const getUser = async () => {
        var user = await window.sessionStorage.getItem('user');
        dispatch({
            type: 'GET_USER',
            payload: JSON.parse(user)
        });
    }

    const setUser = (code) => {

        let urlAuth = `${URL_BASE_AUTH}/validate`;

        axios.post(urlAuth, { code: code })
            .then(async ({ data }) => {
                if (data.error) {
                    throw new Error("Sesión expirada, intenta nuevamente")
                }
                if (!data.data) {
                    throw new Error("Sin información de tu cuenta")
                }
                if (!data.data.userPrincipalName)
                    throw new Error("Sin información de tu cuenta")

                const { userPrincipalName } = data.data;

                //Servicio para obtener los datos del usuario
                var bodyFormData = new FormData();
                bodyFormData.append('correo', userPrincipalName);
  
                generarToken(userPrincipalName).then(async (response) => {
                 //   console.log('response despues del generar token',response)
                    try{
                        if (response.statusCode === 404) {
                            throw new Error("Si estás teniendo problemas para acceder a la plataforma de Toma Tu Foto, te sugerimos verificar el acceso desde un navegador en modo incógnito o revisar la dirección de correo institucional que estas utilizando.");
                        }
                        
                        if (response.message === "Unauthorized") {
                            throw new Error("Tu cuenta no cumple con los requisitos para acceder a este proceso.");
                        }                        
    
                        if (response.data.statusCode === 204) {
                            throw new Error("Tu cuenta no cumple con los requisitos para acceder a este proceso.");
                        }                        
    
                        if (response.data.statusCode === 200) { 
                            response.data.MAIL_INSTITUCIONAL = userPrincipalName;
                           // console.log ('response.data.MAIL_INSTITUCIONAL',response.data.MAIL_INSTITUCIONAL)
                            let parsedUser = {
                                info: response.data.body,
                                token: response.data.token,
                            }
                         //   console.log('parsedUser', JSON.stringify(parsedUser))
                            window.sessionStorage.setItem('user', JSON.stringify(parsedUser))
        
                            dispatch({
                                type: 'SET_USER',
                                payload: parsedUser
                            })
                        }                       
                        
                    } catch (e) {
                        dispatch({
                            type: 'SET_ERROR',
                            payload: e.message
                        })
                    }
                });                
            })
            .catch(e => {
                dispatch({
                    type: 'SET_ERROR',
                    payload: e.message
                })
            })
    }

    const logout = async () => {
        await window.sessionStorage.clear();
        dispatch({
            type: 'LOGOUT',
            payload: null
        })
    }

    return (
        <UserContext.Provider value={{
            user: state.user,
            error: state.error,
            getUser,
            setUser,
            logout
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState;
