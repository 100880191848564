const urlAws = process.env.REACT_APP_URL_AWS;
const user = process.env.REACT_APP_USER_TOKEN;
const password = process.env.REACT_APP_PASSWORD_TOKEN;
const appid = process.env.REACT_APP_APPID_TOKEN; 

export const getSolicitudAWS = async (rut) => {

    const rt = await getTokenAws();    
    if(rt.statusCode !== 200) return [];

    var bRequest = JSON.stringify({ rut }, null, 2);

    var respuesta = await fetch(urlAws + "/banner/tui/estado_solicitud", {
        method: "POST", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "authorizationToken": rt.token
        }       
    });

    var response = await respuesta.json();
    if(respuesta.status === 200) return response;    
    else return [];
}

export const guardarTUI = async (req) => {

    const rt = await getTokenAws();    
    if(rt.statusCode !== 200) return [];

    var bRequest = JSON.stringify({
        rut: req.rut,
        campus: req.campus,
        carrera: req.carrera,
        fotografia: req.fotografia
    }, null, 2);

    console.log(req)

    var respuesta = await fetch(urlAws + "/banner/students/tui", {
        method: "PUT", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "authorizationToken": rt.token
        }       
    });

    var response = await respuesta.json();    

    if(respuesta.status === 200) return response;    
    else return [];
    
}

export const guardarTNE = async (req) => {
       
    const rt = await getTokenAws();    
    if(rt.statusCode !== 200) return [];

    var bRequest = JSON.stringify({
        rut: req.rut,
        campus: req.campus,
        carrera: req.carrera,
        fotografia: req.fotografia
    }, null, 2);

    var respuesta = await fetch(urlAws + "/banner/students/tne", {
        method: "PUT", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "authorizationToken": rt.token
        }       
    });

    var response = await respuesta.json();   

    if(respuesta.status === 200) return response;    
    else return [];

}

export const getTneAWS = async (rut) => {   
   
    const rt = await getTokenAws();    
    if(rt.statusCode !== 200) return rt;

    var bRequest = JSON.stringify({ rut }, null, 2);

    var respuesta = await fetch(urlAws + "/banner/tui/habilitado-tne", {
        method: "POST", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "authorizationToken": rt.token
        }       
    });

    var response = await respuesta.json();    

    if(respuesta.status === 200) return response;    
    else return [];

}

export const getTuiAWS = async (rut) => {
   
    const rt = await getTokenAws();    
    if(rt.statusCode !== 200) return rt;

    var bRequest = JSON.stringify({ rut }, null, 2);

    var respuesta = await fetch(urlAws + "/banner/tui/habilitar-tui", {
        method: "POST", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json', 
            "authorizationToken": rt.token
        }       
    });

    var response = await respuesta.json();   

    if(respuesta.status === 200) return response;    
    else return [];
    
}

export const generarToken = async (correo) => { 

    var bRequest = JSON.stringify({ correo: correo }, null, 2);

    var respuesta = await fetch(urlAws + "/banner/tui/generar-token", {
        method: "POST", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json'
        }       
    });

    var response = await respuesta.json();    

    if(respuesta.status === 200) return response;    
    else return {statusCode: 403, message: "Error al generar Token"};

}

const getTokenAws = async () => {
    
    var bRequest = JSON.stringify({
        user: user,
        password: password,
        appid: appid
    }, null, 2);

    var respuesta = await fetch(urlAws + "/auth/create-token", {
        method: "POST", 
        body: bRequest,
        headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json'
        }       
    });

    var response = await respuesta.json();

    if(respuesta.status === 200) return response;    
    else return {statusCode: 401, message: "Token Error"};
    
}