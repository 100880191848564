import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logout from '@mui/icons-material/Logout'
import { useTheme, useMediaQuery } from '@mui/material';
import { UserContext } from '../../context/User/UserContext';

const Header = () => {

    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.up('sm'));
    const { logout } = React.useContext(UserContext);

    return (
        <>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, backgroundColor: "#fff" }}
            >
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1, my: 1, mx: 1.5 }}>
                        <img src="/images/logo_uss_horizontal.png" width="120" alt="Toma tu Foto" />
                    </Typography>
                    {
                        responsive ?
                            <Button href="#" onClick={logout} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                                Cerrar Sesión
                            </Button> :
                            <Button href="#" onClick={logout} variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                                <Logout />
                            </Button>
                    }
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header;